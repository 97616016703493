import '../scss/style.scss';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import Sticky from 'sticky-js';

document.addEventListener('DOMContentLoaded', function () {
    var openSubElements = document.querySelectorAll(".open-sub");

    openSubElements.forEach(function (openSubElement) {
        openSubElement.addEventListener("click", function () {
            var parentLi = openSubElement.closest("li");
            var navigationLi = parentLi.querySelector(".navigation-li");

            var allNavigationLi = document.querySelectorAll(".navigation-li");
            allNavigationLi.forEach(function (item) {
                if (item !== navigationLi) {
                    item.classList.remove("lg:block", "lg:visible", "slideDown");
                    item.classList.add("lg:hidden", "slideUp");
                }
            });

            navigationLi.classList.toggle("lg:block");
            navigationLi.classList.toggle("lg:hidden");

            if (navigationLi.classList.contains("lg:block")) {
                navigationLi.classList.add("slideDown");
                navigationLi.classList.remove("slideUp");
                openSubElements.forEach(function (item) {
                    item.classList.remove("active");
                });
                openSubElement.classList.add("active");
            } else {
                navigationLi.classList.add("slideUp");
                navigationLi.classList.remove("slideDown");
                openSubElement.classList.remove("active");
            }
        });
    });

    const swiper = new Swiper('.slider-home', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        autoplay: {
            delay: 4000,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            0: {
                autoHeight: true,
            },
            900: {
                autoHeight: false,
            }
        }
    });

    let sticky = new Sticky('.col-fixed');

    const swiper2 = new Swiper('.slider-bubble', {
        modules: [Navigation, Pagination],
        loop: true,
        spaceBetween: 27,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            700: {
                slidesPerView: 2,
            },
            900: {
                slidesPerView: 3,
            }
        }
    });

    Fancybox.bind('[data-fancybox]');

    const accHeads = document.querySelectorAll(".acc-head");
    const accContents = document.querySelectorAll(".acc-content");

    accHeads.forEach((accHead, index) => {
        accHead.addEventListener("click", () => {
            const isAccOpen = accContents[index].classList.contains("show");

            accContents.forEach((content, contentIndex) => {
                content.classList.remove("show");
            });

            accHeads.forEach((head) => {
                head.classList.remove("active");
                head.querySelector("i").classList.replace("fa-xmark", "fa-plus");
            });

            if (!isAccOpen) {
                accContents[index].classList.toggle("show");
                accHead.classList.add("active");
                accHead.querySelector("i").classList.replace("fa-plus", "fa-xmark");
            }
        });
    });

    function handleInputFocusBlur(event) {
        const field = event.target.closest("label");

        if (event.type === "focus" || event.type === "input") {
            field.classList.add("filled");
        } else if (event.type === "blur" && !event.target.value) {
            field.classList.remove("filled");
        }
    }

    const formElements = document.querySelectorAll(".form-home input, .form-home select, .form-home-mobile input, .form-home-mobile select");

    formElements.forEach((element) => {
        element.addEventListener("blur", handleInputFocusBlur);
        element.addEventListener("input", handleInputFocusBlur);
        element.addEventListener("focus", handleInputFocusBlur);
    });

    if (document.querySelector('#fileinput')) {
        document.getElementById('fileinput').addEventListener('change', function (event) {
            const fileInput = event.target;
            const filePreview = document.getElementById('file-preview');
        
            filePreview.innerHTML = '';
            if (fileInput.files.length > 0) {
                const fileName = fileInput.files[0].name;
        
                const fileNameElement = document.createElement('span');
                fileNameElement.textContent = fileName;
        
                const deleteButton = document.createElement('button');
                deleteButton.innerHTML = '<i class="fa-solid fa-xmark text-[1.5rem]"></i>';
                filePreview.classList.add('flex');
                filePreview.classList.remove('hidden');
                deleteButton.addEventListener('click', function () {
                    fileInput.value = '';
                    filePreview.innerHTML = '';
                    filePreview.classList.remove('flex');
                    filePreview.classList.add('hidden');
                });

                filePreview.appendChild(fileNameElement);
                filePreview.appendChild(deleteButton);
            }
        });
    }

    if (document.querySelector('#home-order-select')) {
        document.getElementById('home-order-select').addEventListener('change', function () {
            const selectedIndex = this.selectedIndex;
            const formWrappers = document.querySelectorAll('.form-wrapper');

            formWrappers.forEach((formWrapper, index) => {
                if (index === selectedIndex) {
                    formWrapper.classList.remove('hidden');
                    formWrapper.classList.add('block');
                } else {
                    formWrapper.classList.remove('block');
                    formWrapper.classList.add('hidden');
                }
            });
        });
    }

    if (document.querySelector('.open-mobile')) {
        const openMobileLink = document.querySelector('.open-mobile');
        const closeMobileLink = document.querySelector('.close-mobile');
        const navMain = document.getElementById('nav-main');

        openMobileLink.addEventListener('click', function () {
            navMain.classList.remove('lg:hidden');
            navMain.classList.add('lg:flex');
        });

        closeMobileLink.addEventListener('click', function () {
            navMain.classList.remove('lg:flex');
            navMain.classList.add('lg:hidden');
        });
    }

    const contactForms = document.querySelectorAll('.contact-form');
    let isFormSubmitted = false;

    contactForms.forEach((form) => {
        form.addEventListener('submit', function (event) {
            event.preventDefault();

            form.querySelector('.alert-danger').classList.add('hidden');
            form.querySelector('.alert-success').classList.add('hidden');
    
            const formData = new FormData(form);
            if (isFormSubmitted) {
                return;
            }

            isFormSubmitted = true;

            fetch(form.getAttribute('action'), {
                method: form.getAttribute('method'),
                body: formData
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                isFormSubmitted = false;
                if (data.success) {
                    form.querySelector('.alert-success').classList.remove('hidden');
                    form.reset();
                    //Clear file-preview
                    const filePreview = form.querySelector('#file-preview');
                    if (filePreview) {
                        filePreview.innerHTML = '';
                        //hidden class
                        filePreview.classList.add('hidden');
                    }
                } else {
                    form.querySelector('.alert-danger').classList.remove('hidden');
                }
            })
            .catch(error => {
                isFormSubmitted = false;
                form.querySelector('.alert-danger').classList.remove('hidden');
            });
        });
    });
    

});

